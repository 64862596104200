/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/namesLatvia',[], function () {
    "use strict";

    var  first, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    first = [
		
			
		["Adams"],
		["Adolfs"],
		["Adrians"],
		["Adrians"],
		["Agnis"],
		["Agris"],
		["Aigars"],
		["Ainars"],
		["Ainars"],
		["Ainis"],
		["Aivars"],
		["Aivis"],
		["Alberts"],
		["Aleksandras"],
		["Aleksandrs"],
		["Aleksejs"],
		["Aleksis"],
		["Alfons"],
		["Alfreds"],
		["Aloizs"],
		["Alvis"],
		["Anatolijs"],
		["Andis"],
		["Andrejs"],
		["Andris"],
		["Andžejs"],
		["Anrijs"],
		["Ansis"],
		["Antons"],
		["Arijs"],
		["Aris"],
		["Arkadijs"],
		["Armands"],
		["Armins"],
		["Arnis"],
		["Arnolds"],
		["Artis"],
		["Arturs"],
		["Arturs"],
		["Arvids"],
		["Arvis"],
		["Atis"],
		["Augusts"],
		["Austris"],
		["Boriss"],
		["Brencis"],
		["Bruno"],
		["Dagnis"],
		["Dainis"],
		["Daniels"],
		["Davids"],
		["Davis"],
		["Didzis"],
		["Dmitrijs"],
		["Džeimss"],
		["Dzintars"],
		["Džons"],
		["Džordžs"],
		["Edgars"],
		["Edijs"],
		["Edmunds"],
		["Eduards"],
		["Edvards"],
		["Edvins"],
		["Egils"],
		["Egons"],
		["Einars"],
		["Eižens"],
		["Eliza"],
		["Elmars"],
		["Elvijs"],
		["Elvis"],
		["Elviss"],
		["Emils"],
		["Endijs"],
		["Eriks"],
		["Ernests"],
		["Ervins"],
		["Evalds"],
		["Felikss"],
		["Felikss"],
		["Filips"],
		["Filips"],
		["Fjodors"],
		["Francis"],
		["Fricis"],
		["Fridrihs"],
		["Gatis"],
		["Georgs"],
		["Gintars"],
		["Gintauts"],
		["Gints"],
		["Girts"],
		["Gothards"],
		["Gunars"],
		["Gundars"],
		["Guntars"],
		["Guntis"],
		["Gustavs"],
		["Gusts"],
		["Gvido"],
		["Hanss"],
		["Haralds"],
		["Harijs"],
		["Helmuts"],
		["Henrihs"],
		["Henrijs"],
		["Henriks"],
		["Herberts"],
		["Hermanis"],
		["Ignats"],
		["Igors"],
		["Ilgonis"],
		["Ilgvars"],
		["Ilmars"],
		["Ilvars"],
		["Imants"],
		["Inars"],
		["Indrikis"],
		["Indulis"],
		["Ingars"],
		["Ingus"],
		["Intars"],
		["Ints"],
		["Ivans"],
		["Ivars"],
		["Ivo"],
		["Janis"],
		["Jans"],
		["Jazeps"],
		["Jekabs"],
		["Jevgenijs"],
		["Johans"],
		["Julijs"],
		["Jurgis"],
		["Juris"],
		["Karlis"],
		["Kaspars"],
		["Kazimirs"],
		["Kirils"],
		["Klavs"],
		["Konstantins"],
		["Krišjanis"],
		["Krišs"],
		["Kristaps"],
		["Kristers"],
		["Kristians"],
		["Krists"],
		["Laimdots"],
		["Laimonis"],
		["Lauris"],
		["Leonards"],
		["Leonids"],
		["Leons"],
		["Linards"],
		["Ludis"],
		["Ludvigs"],
		["Luks"],
		["Madars"],
		["Maigonis"],
		["Maikls"],
		["Mairis"],
		["Maksims"],
		["Marcis"],
		["Mareks"],
		["Maris"],
		["Marks"],
		["Markuss"],
		["Martinš"],
		["Matejs"],
		["Matiss"],
		["Miervaldis"],
		["Mihaels"],
		["Mihails"],
		["Mikelis"],
		["Miks"],
		["Mikus"],
		["Modris"],
		["Nauris"],
		["Niklavs"],
		["Nikolajs"],
		["Niks"],
		["Normunds"],
		["Ojars"],
		["Olafs"],
		["Olegs"],
		["Olivers"],
		["Olivje"],
		["Oskars"],
		["Osvalds"],
		["Oto"],
		["Paulis"],
		["Pauls"],
		["Pavels"],
		["Pavils"],
		["Peteris"],
		["Pjotrs"],
		["Raimonds"],
		["Raitis"],
		["Raivis"],
		["Raivo"],
		["Ralfs"],
		["Reinholds"],
		["Reinis"],
		["Renars"],
		["Ricards"],
		["Rihards"],
		["Rinalds"],
		["Ritvars"],
		["Roberts"],
		["Rolands"],
		["Romans"],
		["Romans"],
		["Romualds"],
		["Ronalds"],
		["Rudolfs"],
		["Sandis"],
		["Sandris"],
		["Sergejs"],
		["Simanis"],
		["Simons"],
		["Stanislavs"],
		["Stefans"],
		["Svens"],
		["Talis"],
		["Talivaldis"],
		["Teodors"],
		["Tomass"],
		["Toms"],
		["Ugis"],
		["Uldis"],
		["Vairis"],
		["Valdemars"],
		["Valdis"],
		["Valentins"],
		["Valerijs"],
		["Valters"],
		["Valts"],
		["Vasilijs"],
		["Verners"],
		["Viesturs"],
		["Viktors"],
		["Vilhelms"],
		["Vilis"],
		["Viljams"],
		["Vilnis"],
		["Vilums"],
		["Visvaldis"],
		["Vitalijs"],
		["Vitauts"],
		["Vitolds"],
		["Vladimirs"],
		["Vladislavs"],
		["Voldemars"],
		["Žanis"],
		["Ziedonis"],
		["Zigfrids"],
		["Zigmars"],
		["Zigmunds"],
		["Zigurds"],
		["Zintis"]



	

    ];
	

	
    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
	
		["Berzinšbirch"],
		["Kalninšhill"],
		["Ozolinšoak"],
		["Jansonsson"],
		["Ozolsoak"],
		["Liepinšlinden"],
		["Kruminšbush"],
		["Balodispigeon"],
		["Eglitisfir"],
		["Zarinšbranch"],
		["Petersonsson"],
		["Vitolswillow"],
		["Klavinšmaple"],
		["Karklinšwillow"],
		["Vanagshawk"]	



	
      ];

    return {
        first: first,
        last: last
    };
});
