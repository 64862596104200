/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/namesFinland',[], function () {
    "use strict";

    var  firstFinnish, firstSwedish, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    firstFinnish = [
		
						
			["Veeti"],
			["Eetu"],
			["Onni"],
			["Aleksi"],
			["Leevi"],
			["Elias"],
			["Lauri"],
			["Joona"],
			["Matias"],
			["Leo"],
			["Niilo"],
			["Rasmus"],
			["Oliver"],
			["Miro"],
			["Jimi"],
			["Aatu"],
			["Daniel"],
			["Juho"],
			["Aapo"],
			["Eemeli"],
			["Jesse"],
			["Arttu"],
			["Lenni"],
			["Valtteri"],
			["Otto"],
			["Aaro"],
			["Joel"],
			["Niklas"],
			["Roope"],
			["Ville"],
			["Konsta"],
			["Juuso"],
			["Luka"],
			["Mikael"],
			["Samuel"],
			["Oskari"],
			["Julius"],
			["Luukas"],
			["Jere"],
			["Eelis"],
			["Niko"],
			["Eeli"],
			["Anton"],
			["Eino"],
			["Emil"],
			["Kasper"],
			["Tuomas"],
			["Eemil"],
			["Akseli"],
			["Viljami"]


	

    ];
	
    firstSwedish = [
		
									
			["Emil"],
			["Oliver"],
			["Anton"],
			["William"],
			["Benjamin"],
			["Oscar"],
			["Rasmus"],
			["Alexander"],
			["Lucas"],
			["Felix"],
			["Liam"],
			["Kevin"],
			["Elias"],
			["Linus"],
			["Alex"],
			["Casper"],
			["Robin"],
			["Axel"],
			["Albin"],
			["Isak"],
			["Leo"],
			["Max"],
			["Alvin"],
			["Hugo"],
			["Aron"],
			["Wilmer"],
			["Amos"],
			["Vincent"],
			["Adam"],
			["Jonathan"],
			["Viktor"],
			["Sebastian"],
			["Victor"],
			["Filip"],
			["Lukas"],
			["Oskar"],
			["Simon"],
			["Theo"],
			["Niklas"],
			["Viggo"],
			["Arvid"],
			["Elis"],
			["Hannes"],
			["Jesper"],
			["Joel"],
			["Karl"],
			["Melvin"],
			["Noah"],
			["Walter"],
			["Elliot"]



	

    ];
	
    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
	
			["Aalto"],
			["Aaltonen"],
			["Aho"],
			["Ahola"],
			["Ahonen"],
			["Ahtisaari"],
			["Alatalo"],
			["Annala"],
			["Anttila"],
			["Anttonen"],
			["Aravirta"],
			["Arola"],
			["Asikainen"],
			["Eerola"],
			["Elo"],
			["Erkkilä"],
			["Eskola"],
			["Haapala"],
			["Haapalainen"],
			["Haavisto"],
			["Hakala"],
			["Häkämies"],
			["Hakkarainen"],
			["Häkkinen"],
			["Halme"],
			["Halonen"],
			["Hämäläinen"],
			["Hänninen"],
			["Hannula"],
			["Harju"],
			["Harjula"],
			["Härmälä"],
			["Harri"],
			["Hautala"],
			["Heikkilä"],
			["Heikkinen"],
			["Heino"],
			["Heinonen"],
			["Heiskanen"],
			["Helminen"],
			["Hemilä"],
			["Hietala"],
			["Hiltunen"],
			["Hirvonen"],
			["Hollo"],
			["Honkala"],
			["Honkanen"],
			["Huhtala"],
			["Hurme"],
			["Huttunen"],
			["Hyvärinen"],
			["Hyvönen"],
			["Ignatius"],
			["Ihalainen"],
			["Iivonen"],
			["Ikonen"],
			["Ilves"],
			["Immonen"],
			["Inha"],
			["Isometsä"],
			["Isotalo"],
			["Jaakkola"],
			["Jaakola"],
			["Janhunen"],
			["Jantunen"],
			["Järvelä"],
			["Järveläinen"],
			["Järvenpää"],
			["Järvinen"],
			["Joensuu"],
			["Jokela"],
			["Jokelainen"],
			["Jokinen"],
			["Joutsen"],
			["Juhola"],
			["Junnila"],
			["Junttila"],
			["Juntunen"],
			["Jussila"],
			["Juvonen"],
			["Kaila"],
			["Kallio"],
			["Kanerva"],
			["Kangas"],
			["Kankkunen"],
			["Kapanen"],
			["Karhu"],
			["Kari"],
			["Karjalainen"],
			["Kärkkäinen"],
			["Karppinen"],
			["Karttunen"],
			["Karvonen"],
			["Kauko"],
			["Kauppi"],
			["Kauppila"],
			["Kauppinen"],
			["Kekkonen"],
			["Keskitalo"],
			["Ketola"],
			["Kettunen"],
			["Kimmo"],
			["Kinnunen"],
			["Kivelä"],
			["Kivi"],
			["Kivimäki"],
			["Kivinen"],
			["Klemetti"],
			["Knuutila"],
			["Koivisto"],
			["Koivu"],
			["Koivula"],
			["Kokko"],
			["Kokkonen"],
			["Kolkka"],
			["Koponen"],
			["Korhonen"],
			["Korpela"],
			["Koskela"],
			["Koski"],
			["Koskinen"],
			["Kotilainen"],
			["Kotka"],
			["Kuisma"],
			["Kujala"],
			["Kukkonen"],
			["Kulmala"],
			["Kumpulainen"],
			["Kuoppala"],
			["Kuusela"],
			["Laakkonen"],
			["Laakso"],
			["Laaksonen"],
			["Lahti"],
			["Lahtinen"],
			["Laine"],
			["Laitinen"],
			["Lajunen"],
			["Lappalainen"],
			["Lappi"],
			["Lassila"],
			["Latvala"],
			["Laukkanen"],
			["Laurila"],
			["Lehti"],
			["Lehtinen"],
			["Lehto"],
			["Lehtola"],
			["Lehtonen"],
			["Leino"],
			["Leinonen"],
			["Leppälä"],
			["Leppänen"],
			["Leskinen"],
			["Liikanen"],
			["Linna"],
			["Lipponen"],
			["Litmanen"],
			["Löppönen"],
			["Luoma"],
			["Määttä"],
			["Mäenpää"],
			["Maijala"],
			["Mäkelä"],
			["Mäkeläinen"],
			["Mäki"],
			["Mäkilä"],
			["Mäkinen"],
			["Mäkitalo"],
			["Makkonen"],
			["Malinen"],
			["Mannerheim"],
			["Manninen"],
			["Mäntylä"],
			["Markkula"],
			["Marttila"],
			["Marttinen"],
			["Mattila"],
			["Maunu"],
			["Meri"],
			["Miettinen"],
			["Mikkola"],
			["Mikkonen"],
			["Moilanen"],
			["Mustonen"],
			["Myllylä"],
			["Nenonen"],
			["Nevalainen"],
			["Niemelä"],
			["Niemi"],
			["Nieminen"],
			["Niinistö"],
			["Nikkilä"],
			["Nikula"],
			["Niskala"],
			["Niskanen"],
			["Nurmi"],
			["Nurminen"],
			["Nykänen"],
			["Oiva"],
			["Oivio"],
			["Ojala"],
			["Oksanen"],
			["Olli"],
			["Ollila"],
			["Paananen"],
			["Paasio"],
			["Paavola"],
			["Palander"],
			["Palo"],
			["Partanen"],
			["Parviainen"],
			["Pekkala"],
			["Pekkanen"],
			["Pekkarinen"],
			["Peltola"],
			["Peltonen"],
			["Pentti"],
			["Penttilä"],
			["Penttinen"],
			["Perttula"],
			["Pesonen"],
			["Pietarila"],
			["Pietarinen"],
			["Pietilä"],
			["Pirhonen"],
			["Pitkänen"],
			["Puhakka"],
			["Pukki"],
			["Pulkkinen"],
			["Räisänen"],
			["Raita"],
			["Rajala"],
			["Ranta"],
			["Rantala"],
			["Rantanen"],
			["Räsänen"],
			["Rautiainen"],
			["Rautio"],
			["Rehn"],
			["Reijonen"],
			["Rekola"],
			["Repo"],
			["Rissanen"],
			["Ruotsalainen"],
			["Saarela"],
			["Saari"],
			["Saarinen"],
			["Salmela"],
			["Salmi"],
			["Salminen"],
			["Salo"],
			["Salonen"],
			["Savela"],
			["Savolainen"],
			["Selänne"],
			["Seppälä"],
			["Seppänen"],
			["Sibelius"],
			["Siimes"],
			["Sillanpää"],
			["Siltala"],
			["Simola"],
			["Simonen"],
			["Sirkka"],
			["Soini"],
			["Soininen"],
			["Somerkoski"],
			["Soro"],
			["Sundqvist"],
			["Suomalainen"],
			["Suomi"],
			["Suominen"],
			["Sutela"],
			["Tammi"],
			["Tamminen"],
			["Tanner"],
			["Tapio"],
			["Terho"],
			["Tikkanen"],
			["Timonen"],
			["Toivanen"],
			["Toivonen"],
			["Tuomaala"],
			["Tuomainen"],
			["Tuomela"],
			["Tuominen"],
			["Tuomioja"],
			["Tuomola"],
			["Turunen"],
			["Ukkonen"],
			["Uljas"],
			["Uosukainen"],
			["Uotila"],
			["Urpilainen"],
			["Uusitalo"],
			["Vainio"],
			["Väisänen"],
			["Valtonen"],
			["Vanhatalo"],
			["Varis"],
			["Varma"],
			["Vasara"],
			["Väyrynen"],
			["Venäläinen"],
			["Vennamo"],
			["Vepsäläinen"],
			["Vesa"],
			["Viinanen"],
			["Viitala"],
			["Viitanen"],
			["Virolainen"],
			["Virta"],
			["Virtanen"],
			["Vuorela"],
			["Vuorinen"],
			["Waris"],
			["Wirtanen"],
			["Ylitalo"],
			["Yrjölä"]




	
      ];

    return {
        firstFinnish: firstFinnish,
		firstSwedish: firstSwedish,
        last: last
    };
});
